
import { Vue, Component } from 'vue-property-decorator';
import { loadPDDConfig, updatePDDConfig } from "@/api/taoke/tkTaokeConfig";

@Component({
  name: 'pddConfig'
})
export default class extends Vue {
  configForm:{
    pddPId: string
    pddClientSecret: string
    pddClientId: string
  } = {
    pddPId: '',
    pddClientSecret: '',
    pddClientId: ''
  }
  isEdit = false

  mounted(){
    this.load3rdConfig();
  }

  load3rdConfig(){
    loadPDDConfig().then(res => {
      this.configForm = res.data
    })
  }
  handleSubmitEdit(){
    updatePDDConfig(this.configForm).then(res => {
      this.$message({
        type: 'success',
        message: '操作成功!'
      });
      this.isEdit = false
    })
  }
  handleEdit(isEdit:boolean){
    this.isEdit = isEdit
  }
}
